import {
    BankOutlined,
    CheckCircleFilled,
    DeleteFilled,
    EditFilled,
    ExclamationCircleFilled,
    LockFilled,
    MehOutlined,
    StockOutlined
} from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import { UserStatus } from "../../../../core/models/dashboard/users/user";

export interface TagStatusProps {
    status: number
}

const TagStatusComponent: React.FC<TagStatusProps> = props => {

    return (
        <>
            {props.status == 0 &&
                (
                    <Tag className="active" key={0}>
                        <CheckCircleFilled />  Active
                    </Tag>
                )
            }
            {props.status == 1 &&
                (
                    <Tag className="bank-disconnected " key={1}>
                        <BankOutlined /> Bank disconnected
                    </Tag>
                )
            }
            {props.status == 2 &&
                (
                    <Tag className="bank-connected" key={2}>
                        <BankOutlined /> Bank connected
                    </Tag>
                )
            }
            {props.status == 3 &&
                (
                    <Tag className="delete-request" key={3}>
                        <DeleteFilled /> Delete request
                    </Tag>
                )
            }
            {props.status == 4 &&
                (
                    <Tag className="not-verified" key={4}>
                        <ExclamationCircleFilled /> Not verified
                    </Tag>
                )
            }
            {props.status == 5 &&
                (
                    <Tag className="onboarding" key={5}>
                        <EditFilled />  Onboarding
                    </Tag>
                )
            }
            {props.status == 6 &&
            (
                <Tag className="not-identified" key={6}>
                    <LockFilled />  Not identified
                </Tag>
            )
            }
            {props.status == 7 &&
            (
                <Tag className="delete-request" key={7}>
                    <LockFilled />  Deleted
                </Tag>
            )
            }
            {props.status == 8 &&
            (
                <Tag className="maininvest-investors" key={8}>
                   <StockOutlined /> Mainvest investors
                </Tag>
            )
            }
              {props.status == 9 &&
            (
                <Tag className="bank-disconnected" key={9}>
                   <MehOutlined /> Subscription Downgrade
                </Tag>
            )
            }
        </>
    )
}

export default TagStatusComponent;