import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Empty, Input, Pagination, Radio, Row, Spin, Table, Tag } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { connect } from 'react-redux';
import { AppState } from '../../../core/commons/reduxStore';
import { UserModel, UserSearch, UserStatus } from "../../../core/models/dashboard/users/user";
import { updateUsersListInStoreAction } from "../../../core/actions/dashboard/users/updateUserListInStore";
import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';
import '../../../styles/layout/index.less';
import moment, { Moment } from 'moment'
import { Link, useHistory } from 'react-router-dom';
import BreadcrumbComponent, { BreadcrumbProps } from '../../common/components/dataDisplay/BreadcrumbComponent';
import TagStatusComponent, { TagStatusProps } from '../../common/components/dashboard/UserTagStatusComponent';
import { RoutePaths } from '../../routes';

const { RangePicker } = DatePicker;
const { Search } = Input;
enum FilterDateType {
    CretatedAt,
    Login
}
const initSearchParams: UserSearch = {
    orderByDescending: false,
    pageNumber: 1,
    pageSize: 10,
    searchValue: "",
    sortColumn: "fullName",
    status: null,
    createdAt: {
        from: null,
        to: null
    },
    loginAt: {
        from: null,
        to: null
    }
}

interface UsersProps {
    updateUsersListInStoreAction: (user: UserModel | null, searchParams: UserSearch) => void;
}

const UserList: React.FC<UsersProps> = (props: any) => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [isMounting, setMounting] = useState(true);
    const [currentSearch, setSearch] = useState<string>()
    const [currentLabelSearch, setSearchLabelResult] = useState<string>()
    const [currentState, setCurrentState] = useState<UserModel>();

    const [filterByDate, setCurrentFilterByCreatedDate] = useState<any>(null);
    const [filterDateType, setCurrentFilterDateType] = useState<any>(FilterDateType.CretatedAt);

    const maxSearchLength = 50;
    const userStatus: TagStatusProps = { status: 0 };

    const currentBreadCrumbs: BreadcrumbProps = {
        title: "Dashboard",
        className: "breadcrumb",
        description: "",
        breadcrumbs: [
            {
                title: "Users",
                path: ""
            }
        ]
    };

    const onFilterTypeChanged = (value: any) => {
        setLoading(true);
        setCurrentFilterDateType(value.target.value);
        setCurrentFilterByCreatedDate(null);
        initSearchParams.loginAt.from = null;
        initSearchParams.loginAt.to = null;
        initSearchParams.createdAt.from = null;
        initSearchParams.createdAt.to = null;
        initSearchParams.pageNumber = 1;

        props.updateUsersListInStoreAction(null, initSearchParams);
    }

    const onCalendarChange = (value: any) => {
        setLoading(true);
        setCurrentFilterByCreatedDate(value);
        onCalendarChangeConfirm(value);
    }

    const onCalendarChangeConfirm = (value: any) => {
        let from: any = null;
        let to: any = null;

        if (value) {
            if (value.length > 0) {

                if (value[0] != null) {
                    from = value[0].format("YYYY-MM-DD");
                } else {
                    from = null;
                }
                if (value[1] != null) {
                    to = value[1].format("YYYY-MM-DD");
                } else {
                    to = null;
                }
                
            } else {
                from = null;
                to = null;
            }

        } else {
            from = null;
            to = null;
        }

        searchByDateValue(filterDateType, from, to);
    }

    const searchByDateValue = (filterType: FilterDateType, fromValue: any, toValue: any) => {
        if (filterType == FilterDateType.CretatedAt) {

            initSearchParams.createdAt.from = fromValue;
            initSearchParams.createdAt.to = toValue;
            initSearchParams.loginAt.from = null;
            initSearchParams.loginAt.to = null;
        }
        if (filterType == FilterDateType.Login) {
            initSearchParams.loginAt.from = fromValue;
            initSearchParams.loginAt.to = toValue;
            initSearchParams.createdAt.from = null;
            initSearchParams.createdAt.to = null;
        }
        if (fromValue == null && toValue == null) {
            initSearchParams.pageNumber = 1;
        }

        props.updateUsersListInStoreAction(null, initSearchParams);
    }



    const columns = [
        {
            title: 'Full name',
            width: 250,
            dataIndex: 'fullName',
            key: 'fullName',
            fixed: true,
            sorter: true,

            render: (fullName: string, row: any) => (
                <> { fullName ?
                    (fullName.length > 30 ?
                        (<>
                            <Button onClick={() => { onClickUser(row.id) }} type="link">
                                <span className="table-item">
                                    <span className="alphabetic-item">{fullName.toString().trimStart().trimEnd().substring(0, 1)}</span>
                                    <span className="item-concat"> {fullName.substring(0, 30)}...<br />
                                        <span className="email-item">{row.email}</span>
                                    </span>
                                </span>
                            </Button>

                        </>)
                        : (<>
                            <Button onClick={() => { onClickUser(row.id) }} type="link">
                                <span className="table-item">
                                    <span className="alphabetic-item">{row.fullName.substring(0, 1)}</span>
                                    <span className="item-concat"> {fullName}<br />
                                        <span className="email-item">{row.email}</span>
                                    </span>
                                </span>
                            </Button>
                        </>)
                    ) :
                    ""}
                </>
            )
        },
        {
            title: 'Corepro Customer ID',
            width: 220,
            dataIndex: 'coreProCustomerId',
            key: 'coreProCustomerId',
            sorter: true,

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            filterMultiple: false,
            sorter: true,

            filters: [
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.Active }} /></>,
                    value: UserStatus.Active,
                },
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.BankConnected }} /></>,
                    value: UserStatus.BankConnected,
                },
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.BankDisconnected }} /></>,
                    value: UserStatus.BankDisconnected,
                }, {
                    text: <><TagStatusComponent {...{ status: UserStatus.DeletedRequest }} /></>,
                    value: UserStatus.DeletedRequest,
                }, {
                    text: <><TagStatusComponent {...{ status: UserStatus.NotVerified }} /></>,
                    value: UserStatus.NotVerified,
                },
                {
                    text: <><TagStatusComponent {...{ status: UserStatus.OnBoarding }} /></>,
                    value: UserStatus.OnBoarding,
                },
            ],
            render: (status: UserStatus) => (
                <>
                    <TagStatusComponent {...{ status: status }} />
                </>
            ),
        },
        {
            title: 'Device',
            dataIndex: 'deviceType',
            key: 'deviceType',
            width: 100,
            sorter: true,
            render: (deviceType: string) => (
                <> {deviceType ? (
                    deviceType.length > 20 ?
                        `${deviceType?.substring(0, 20)}...` : deviceType) : ""} </>
            )
        },
        {
            title: 'App version',
            dataIndex: 'appVersion',
            key: 'appVersion',
            width: 100,
            sorter: true,
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            sorter: true,
            render: (createdAt: Date) => (<> { createdAt ? `${moment(new Date(createdAt)).format("MM-DD-YYYY")}` : null} </>),
        },
        {
            title: 'Last login',
            dataIndex: 'lastModifiedAt',
            key: 'lastModifiedAt',
            width: 120,
            sorter: true,
            render: (lastModifiedAt: Date) => (
                <> { lastModifiedAt ? `${moment(new Date(lastModifiedAt)).format("MM-DD-YYYY")}` : null} </>
            )
        },
        {
            title: '',
            key: 'action',
            fixed: true,
            dataIndex: 'id',
            width: 100,
            render: (id: string) =>
                <>
                    <Button onClick={() => { onClickUser(id) }} type="link">
                        <ArrowRightOutlined />
                    </Button>
                </>
        },
    ];

    if (isMounting) {
        setLoading(true);
        setMounting(false);
        props.updateUsersListInStoreAction(null, initSearchParams);
    }

    const onSearchSubmit = (searchValue: string) => {
        setSearchLabelResult('');
        setLoading(true);
        setSearch(searchValue);
        initSearchParams.searchValue = searchValue;
        initSearchParams.pageNumber = 1;
        props.updateUsersListInStoreAction(null, initSearchParams);
    }

    const onClickUser = (id: string) => {
        history.push(`/admin/users/user-details/${id}`);
    }

    useEffect(() => {
        console.log("users: ", props.users)
        if (props.users) {
            if (!currentState) {
                setCurrentState(props.users)
            }
            if (currentSearch && currentState && !Object.is(currentState, props.users)) {
                setSearchLabelResult(`${props.users?.totalItemCount} results for “${currentSearch}”`)
                setLoading(false);
                setCurrentState(props.users)
            }
            if (!Object.is(currentState, props.users)) {

                setCurrentState(props.users)
                setLoading(false);
            }
        }
    });

    const onChangeEvent = (pagination: any, filters: any, sorter: any, extra: any) => {
        setLoading(true);
        if (filters) {
            if (filters.status != undefined || filters.status != null) {
                initSearchParams.status = Number(filters.status[0]);
            } else {
                initSearchParams.status = null;
            }
        }

        if (sorter) {
            if (sorter.order || sorter.undefined) {
                initSearchParams.sortColumn = sorter.field;
                initSearchParams.orderByDescending = (sorter.order == 'ascend') ? false : true;

            } else {
                initSearchParams.sortColumn = 'createdAt';
                initSearchParams.orderByDescending = true;
            }
        }

        if (pagination) {

            if (props.users?.pageNumber != pagination.current) {

                initSearchParams.pageNumber = pagination.current;

            }
        };
        props.updateUsersListInStoreAction(null, initSearchParams);
    }

    const disabledDate = (current: Moment): boolean => {
        return (current < moment(Date.now())) ? false : true;
    }

    return (
        <Content >
            <BreadcrumbComponent {...currentBreadCrumbs}></BreadcrumbComponent>
            <Spin spinning={isLoading}>
                <Row className="dashboard-content">
                    <Row className="row-card">
                        <Card className="card-item">
                            <Row className="search-box ">
                                <h5 className="title">Search users</h5>
                                <h6 className="description">Enter name or email address.</h6>
                                <Search size="large"
                                    name="searchValue"
                                    className="search-input"
                                    enterButton="Search"
                                    maxLength={maxSearchLength}
                                    placeholder="Enter name or email address"
                                    onSearch={onSearchSubmit}
                                    prefix={<SearchOutlined />}
                                />
                            </Row>
                            <Row className="calendar-picker">
                                <Row className="radio-calendar-filter">
                                    <RangePicker className="dateRangePicker" allowEmpty={[true, true]} value={filterByDate} defaultPickerValue={filterByDate} onCalendarChange={onCalendarChange}
                                        disabledDate={disabledDate}
                                    >
                                    </RangePicker>

                                    <Radio.Group onChange={onFilterTypeChanged} value={filterDateType} className="radio-group-filter">
                                        <Radio value={FilterDateType.CretatedAt}>Created</Radio>
                                        <Radio value={FilterDateType.Login}>Last login</Radio>
                                    </Radio.Group>
                                </Row>
                            </Row>
                        </Card>
                    </Row>

                    {currentLabelSearch ? (
                        <Row className="row-card">
                            <h5 className="result-details">
                                {currentLabelSearch}
                            </h5>
                        </Row>
                    ) : ("")}

                    <Row className="row-card">
                        {props.users ? (
                            <Card className="card-item">
                                <Table
                                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results for ths search. Try again..." /> }}
                                    pagination={{
                                        total: props.users?.totalItemCount, current: props.users?.pageNumber,
                                        pageSize: props.users?.pageSize,
                                        showSizeChanger: false,
                                    }}
                                    scroll={{ x: 1200 }}
                                    onChange={(onChangeEvent)}
                                    sortDirections={['ascend', 'descend', 'ascend']}
                                    columns={columns} dataSource={props.users?.items} />
                            </Card>
                        ) : ("")}
                    </Row>
                </Row>
            </Spin>
        </Content>
    )
}

const mapStateToProps = ({ users }: AppState) =>
({
    users: users
});

export default connect(mapStateToProps, {
    updateUsersListInStoreAction
})(UserList);

